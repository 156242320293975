import React from 'react';

import Badge from 'react-bootstrap/Badge'

import './hero.scss';

export default function Hero() {

    return (
        <section className='hero d-flex-column'>
            <div className="hero-content d-flex-column">
                <div className="hero-display d-flex-column">
                    <h1 className='display-1'>Maximizing Events, Multiplying <span className='text-gradient hero-linear-gradient'>Profits</span>.</h1>
                    <Badge bg="info" className='small fw-medium text-dark badge--tickets'>Enhanced Ticketing</Badge>
                </div>

                <svg className="arrow" width="24" height="39" viewBox="0 0 24 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5 2.00098C13.5 1.17255 12.8284 0.500977 12 0.500977C11.1716 0.500977 10.5 1.17255 10.5 2.00098L13.5 2.00098ZM10.9393 38.0616C11.5251 38.6474 12.4749 38.6474 13.0607 38.0616L22.6066 28.5157C23.1924 27.9299 23.1924 26.9802 22.6066 26.3944C22.0208 25.8086 21.0711 25.8086 20.4853 26.3944L12 34.8797L3.51472 26.3944C2.92893 25.8086 1.97919 25.8086 1.3934 26.3944C0.807613 26.9802 0.807613 27.9299 1.3934 28.5157L10.9393 38.0616ZM10.5 2.00098L10.5 37.001L13.5 37.001L13.5 2.00098L10.5 2.00098Z" fill="#141416" />
                </svg>


            </div>
            <div className="hero-text-container">
                <div className='hero-text'>
                    <h4 className='fw-bold mb-3'>A different approach that truly benefits venues.</h4>
                    <p>We combine a user-friendly, mobile-first ticketing platform with hands-on event production services. We provide under-utilized venues with a streamlined solution for managing tickets and producing successful events in order to increase the bottom line.</p>
                </div>
            </div>
        </section>
    );
}
