import React, { useState } from 'react';

import Stack from 'react-bootstrap/Stack'
import { FlipCardModal } from '../../FlipCardModal';
import FlipCardModalBody from '../../FlipCardModal/FlipCardModalBody';
import { FlipCard } from '../FlipCard';

export default function SecondaryFeatureCards() {

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', text: '' });
    const toggleModal = () => {
        setShowModal(!showModal);
    }

    const cards = [
        {
            id: "1",
            front: {
                title: "Simplified Guest List."
            },
            back: {
                title: 'Manage guests.',
                text: 'Your event guest list updates in real time, and adding and removing guests from your list is as simple as a single click. Each newly added guest receives their tickets via text moments after they\'re added to your list, making their check-in experience seamless.'
            }
        },
        {
            id: "2",
            front: {
                title: "Virtural Box Office."
            },
            back: {
                title: 'Scan, Pay, Enter.',
                text: 'Transform your event entry with our Virtual Box Office. It allows event organizers to replace traditional methods by enabling fans to purchase tickets at the door with a simple QR code scan. This system ensures cashless transactions, securely managed through our platform.'
            }
        },
        {
            id: "3",
            front: {
                title: "Create Your Community."
            },
            back: {
                title: 'Elevate your fans.',
                text: 'Before or after your event, you can directly connect with your fans via personalized outreach and reward them with special offers, VIP access, limited-edition collectibles, community membership, and more - all through leveraging your data insights. You are now an additional asset to your fans.'
            }
        }
    ]

    const doNothing = (id) => {
        // do nothing
        console.log(id);
    }

    return (
        <section className='secondary-features'>
            <FlipCardModal modalOpen={showModal} handleClose={toggleModal} headingText={modalContent.title}>
                <FlipCardModalBody textContent={modalContent.text}></FlipCardModalBody>
            </FlipCardModal>
            <Stack gap={4} className='secondary-features-grid flex-lg-row justify-content-center align-items-center'>
                {/* DEV, This is just for now, dunno if we want these to have overlay */}
                <FlipCard toggleOverlay={doNothing} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={11} obj={cards[0]} variant="light" size="sm" id="guest-list" />
                <FlipCard toggleOverlay={doNothing} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={12} obj={cards[1]} variant="dark" size="sm" id="mobile" />
                <FlipCard toggleOverlay={doNothing} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={13} obj={cards[2]} variant="light" size="sm" id="fandom" />
            </Stack>
        </section>
    );
}
