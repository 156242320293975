import React, { useState } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FlipCard } from '../FlipCard';
import { FlipCardModal } from '../../FlipCardModal';
import FlipCardModalBody from '../../FlipCardModal/FlipCardModalBody';

import noFeesMobile from "../../../assets/animations/nofees-mobie.json"
import noFees from "../../../assets/animations/no-fees.json"
import bidding from "../../../assets/animations/bidding.json"
import chartCol from "../../../assets/animations/chart-column.json"
import graph from "../../../assets/animations/graph.json"
import graphMobile from "../../../assets/animations/graph-mobile.json"
import paymentOptions from "../../../assets/animations/payment-options.json"
import priceDrag from "../../../assets/animations/price-drag.json"
import ticketScan from "../../../assets/animations/ticket-scan.json"
import message from "../../../assets/animations/message.json"

import './feature-cards.scss';

export default function FeatureCards() {

    // -1 overlay not visible
    // Any other number corresponding to a cardId
    // means it is visible, plus then we set the card to have z-index-1001
    const [cardId, setCardId] = useState(-1);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', text: '' });

    const toggleOverlay = (id) => {
        setCardId(id);
    };
    const toggleModal = () => {
        setShowModal(!showModal);
    }

    const cards = [
        {
            id: "1",
            front: {
                title: "All-in Pricing.",
                subtitle: "Eliminates surprise fees at checkout."
            },
            back: {
                title: 'No hidden fees!',
                text: 'We believe in transparency and simplicity. That\'s why when you choose us for ticketing your event, you can rest assured that your fans will not encounter any hidden fees at checkout. Our commitment to all-in pricing ensures a straightforward and clear cost structure. This approach guarantees a hassle-free, transparent ticketing experience – dedicated to the success of your event without burdening your fans with unexpected costs.'
            }
        },
        {
            id: "2",
            front: {
                title: "Zero Fraud.",
                subtitle: "Protection from bots and scalpers."
            },
            back: {
                title: 'Our security isn\'t just robust - it\'s evolved.',
                text: 'The introduction of BASS (Blocktickets Anti-Scalping System) sets a new standard in ticketing security. Alongside CAPTCHA Challenges and Rate Limiting, BASS ensures every ticket sold is genuine. We\'ve further enhanced security by requiring account verification with a government-issued ID for high demand events, adding an extra layer of protection against fraudulent activities. This comprehensive approach effectively thwarts sophisticated bots and fraudulent ticketing schemes, rendering ticket fraud obsolete.'
            }
        },
        {
            id: "3",
            front: {
                title: "2-Click Checkout."
            },
            back: {
                title: 'Pay with ease.',
                text: 'Fans don\'t have to worry about navigating a complex checkout process. Our integrated 2-click checkout maximizes conversion rates and optimizes ticket sales. While our system ensures streamlined transactions, payments can be made using your favourite methods such as credit card, Apple Pay, and Google Pay, offering both convenience and flexibility.'
            }
        },
        {
            id: "4",
            front: {
                title: "Earn Revenue From Resales.",
                subtitle: 'Capture profits from all tickets sold on our secondary marketplace.',
                highlightText: ['Revenue', 'Resales']
            },
            back: {
                title: 'Increased income.',
                text: 'Our ticketing system is designed to benefit event organizers at every turn. Every time a ticket to your event is resold on our secondary marketplace, a portion of that sale is directed back to you. This means your earning potential doesn\'t stop at the first sale; it continues as tickets change hands, providing you with an ongoing revenue stream. This approach ensures that you benefit from the full lifecycle of ticket sales, maximizing your earnings from each event.'
            }
        },
        {
            id: "5",
            front: {
                title: "Own Your Resale Market."
            },
            back: {
                title: 'Fair pricing on the resale market.',
                text: 'By using our platform, you as the event organizer set the minimum and maximum resale prices for your event tickets. This ensures that true fans are protected from price gouging, making your events more accessible and fan-friendly. This approach creates a win-win scenario: fans can secure tickets at fair prices, fostering loyalty and a positive event experience.     '
            }
        },
        {
            id: "6",
            front: {
                title: "Easily Transfer or Sell Tickets.",
                highlightText: ['Transfer', 'Sell']
            },
            back: {
                title: 'Can\'t attend an event? No problem.',
                text: 'Say goodbye to the hassle of posting ads on social media or arranging meetings with strangers at random locations for ticket exchanges. Now, selling or transferring your tickets is as simple as a single click! This streamlined process provides a smooth, secure, and hassle-free experience, whether you\'re passing tickets to a friend or selling them to a fellow fan.'
            }
        },
        {
            id: "7",
            front: {
                title: "Powerful Insights."
            },
            back: {
                title: 'Own your data, own your event.',
                text: 'Your data gives you the control you need over your event experiences and fan relationships. We provide holistic event and fan data, as well as primary and resale ticket and market data so you can separate your event experiences, marketing strategy, and fan community from the crowd.'
            }
        },
        {
            id: "8",
            front: {
                title: "Auctions.",
                label: 'Coming soon'
            },
            back: {
                title: 'Everyone has their price.',
                text: 'When an event sells out, an auction opens to allow fans the opportunity to bid on owned tickets. This entices ticket owners to sell their tickets to the highest bidder. '
            }
        },
        {
            id: "9",
            front: {
                title: "NFT Collectibles.",
                label: 'Coming soon'
            },
            back: {
                title: 'Digital collectables.',
                text: 'NFT tickets aren\'t just keys to enter an event. They are digital collectibles, meaning that the fan experience does not end when the event does. Each ticket is customizable, and organizers can pack them with perks, leverage them to build communities, programmatically airdrop rewards and additional digital collectibles, and much more.'
            }
        },
        {
            id: "10",
            front: {
                title: "Airdrops.",
                label: 'Coming soon'
            },
            back: {
                title: 'Incentivize your fans.',
                text: 'Connect with your fans before and after your events by airdropping gifts, VIP perks, offers, and limited-edition collectibles that can be resold directly into their wallets, all while organically building exclusive communities with aligned incentives. '
            }
        }
    ]
    return (
        <section className='spacer feature-cards'>
            <FlipCardModal modalOpen={showModal} handleClose={toggleModal} headingText={modalContent.title}>
                <FlipCardModalBody textContent={modalContent.text}></FlipCardModalBody>
            </FlipCardModal>
            <Row className='info-container'>
                <div className={`card-overlay ${cardId !== -1 ? 'fade-in' : ''}`}></div>
                {/* cardId 0 & 3 make cards flip on the X axis, beware */}
                <Col xs={12} className={`${cardId == 0 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={0} textAlign="center" obj={cards[0]} animation={noFees} mobileAnimation={noFeesMobile} variant="black" id="fees" />
                </Col>
                <Col lg={6} className={`${cardId == 1 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={1} obj={cards[1]} animation={ticketScan} variant="light" id="ticket-scan" textVariant="success" />
                </Col>
                <Col lg={6} className={`${cardId == 2 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={2} obj={cards[2]} animation={paymentOptions} variant="dark" id="payments" />
                </Col>
                {/* cardId 0 & 3 make cards flip on the X axis, beware */}
                <Col xs={12} className={`${cardId == 3 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={3} obj={cards[3]} animation={graph} mobileAnimation={graphMobile} textAlign="center" id="revenue" subtitleSize="sm" highlightVariant="tertiary" />
                </Col>
                <Col lg={6} className={`${cardId == 4 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={4} obj={cards[4]} animation={priceDrag} variant="light" id="market" />
                </Col>
                <Col lg={6} className={`${cardId == 5 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={5} obj={cards[5]} animation={message} variant="dark" id="sell" highlightVariant="primary" />
                </Col>

                <Col lg={6} className={`${cardId == 6 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={6} obj={cards[6]} animation={chartCol} variant="dark" id="insights" />
                </Col>
                <Col lg={6} className={`${cardId == 7 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={7} obj={cards[7]} animation={bidding} id="auctions" />
                </Col>
                <Col lg={6} className={`${cardId == 8 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={8} obj={cards[8]} id="collectables" />
                </Col>
                <Col lg={6} className={`${cardId == 9 ? 'z-index-998' : 'z-index-neutral'}`}>
                    <FlipCard toggleOverlay={toggleOverlay} toggleModal={toggleModal} setModalContent={setModalContent} showModal={showModal} cardId={9} obj={cards[9]} id="airdrops" variant="light" />
                </Col>
            </Row>
        </section>
    );
}
