import React from 'react';
import Modal from 'react-bootstrap/Modal';
import closeSquare from "../../assets/icons/minus-square.svg"
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import "./FlipCardModal.scss"

// Built as a general use modal, made back into a specific one
export default function FlipCardModal({modalOpen, handleClose, headingText, children}) {

    return (
        <Modal id="modal-base" centered animation={false} fullscreen="md-down" show={modalOpen} backdrop="static">
            <Modal.Body className="modalbase_body">
                <h1 className='modalbase_heading'>{headingText}</h1>
                {children}
                <Button className='flipModalClose' variant="link" onClick={() => handleClose()}>
                    <Image
                        src={closeSquare}
                        alt="Close"
                        width="40"
                        height="41"
                    />
                </Button>
            </Modal.Body>
        </Modal>
    )
}