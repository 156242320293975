import React from 'react';
import { useInView } from "react-intersection-observer";

export default function CTA() {

    // DEV make this better
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    return (
        <section className='cta'>
            <div ref={ref}>
                <div className={`text-center cta_inner_div ${inView ? 'text_appear_two' : ''}`}>
                    <h1 className={`display-4 mb-3 tat'`}>Now that you've seen what we're about, let's talk.</h1>
                    <a className={`btn btn-lg btn-primary`} href='mailto:help@blocktickets.xyz' target="_blank">Get in touch</a>
                </div>
            </div>
        </section>
    );
}
